import { PageTags } from '@rally/analytics/dist/models/events';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logPageNav, logModalPageNav } from 'scripts/thunks/analytics-thunks';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { selectFeatureFlags } from 'scripts/selectors/app-selectors';
import { IFeatureFlags } from 'scripts/util/constants/environment.interfaces';
import CONFIG from 'scripts/util/constants/config';
import { setCurrentPageAnalyticsData } from 'scripts/reducers/app-reducer';

/**
 * A hook for tracking page nav and modal page nav events
 * @param pageName A suitable string name for the tracked page/modal
 * @param pageTags An array of string values of enum type PageTags, to set tags for tracked page/modal
 * @param isModal Boolean value to choose between page nav and modal page nav event logging
 * @param shouldLog Boolean value to decide if logging should be enabled; useful
 * @param additionalData Object that has additional data to be passed to analytics
 *  when hook is called from a place where logging isn't needed for all experiences
 *  (should be removed when redundant as part of GEN-195)
 */
export function usePageTracking(
  pageName: string,
  pageTags: PageTags,
  isModal: boolean = false,
  shouldLog: boolean = true,
  additionalData?: object,
): void {
  const dispatch = useDispatch();
  const rallyAnalyticsEnabled = useSelector<IReduxState, IFeatureFlags>(
    selectFeatureFlags,
  ).ARCADE_FEATURES_RALLY_ANALYTICS;

  const sendEventWithImpressions = (): void => {
    setTimeout(() => {
      const dataUiElements = Array.from(document.querySelectorAll('[data-ui-element-name]'));
      const dataUiSections = Array.from(document.querySelectorAll('[data-ui-section]'));

      const elementImpressions = dataUiElements.map(el => el.getAttribute('data-ui-element-name'));
      const sectionImpressions = dataUiSections.map(el => el.getAttribute('data-ui-section'));

      const impressionsAdditionalData = {
        'UI Element Impressions': elementImpressions,
        'UI Section Impressions': sectionImpressions,
      };

      dispatch(logPageNav(pageName, pageTags, {}, impressionsAdditionalData));
    }, CONFIG.ARCADE_WEB_TRACKING_DELAY_MS);
  };

  useEffect(() => {
    if (rallyAnalyticsEnabled && shouldLog) {
      dispatch(setCurrentPageAnalyticsData({ pageName, pageTags, pageArgs: undefined, additionalData }));

      if (isModal) {
        dispatch(logModalPageNav(pageName, pageTags, undefined, additionalData));
      } else {
        sendEventWithImpressions();
      }
    }
  }, []);
}
