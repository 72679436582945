import { createSelector, Selector } from '@reduxjs/toolkit';
import {
  IClaimsPreferences,
  ICommunicationPreferences,
  IDependentAndCoverageTypes,
  IFinancialPreferences,
  IPrimaryCareFpc,
  IPrimaryCarePerMember,
  IProducts,
  IProfile,
  IProfileUser,
  IProviderVideo,
  IReferralsPerMember,
  IOrderCovidTestKitInfo,
  IAccountCard,
} from 'scripts/api/profile/profile.interfaces';
import { IReduxState, IStateData } from 'scripts/reducers/reducer.interfaces';
import { hasLedgerAccess } from 'scripts/util/profile/profile';
import { getYear } from '../util/date/date';
import { CoverageType, CoverageStatus } from 'scripts/api/api.interfaces';

const selectProfile = (state: IReduxState): IStateData<IProfile> => state.profile.profile;

export const selectSelectedProfile: (state: IReduxState) => IProfileUser = (state: IReduxState): IProfileUser =>
  state.profile.selectedProfile;

const selectActivateProviderVideoKey = (state: IReduxState): IStateData<IProviderVideo> =>
  state.profile.providerVideoKey;

export const selectActivateProviderVideoKeyData = createSelector(
  selectActivateProviderVideoKey,
  providerVideoKey => providerVideoKey.data,
);

const selectClaimsPreferences = (state: IReduxState): IStateData<IClaimsPreferences> => state.profile.claimsPreferences;

export const selectCommunicationPreferences = (state: IReduxState): IStateData<ICommunicationPreferences> =>
  state.profile.communicationPreferences;

export const selectProducts = (state: IReduxState): IStateData<IProducts> => state.profile.products;

export const selectReferrals = (state: IReduxState): IStateData<IReferralsPerMember> => state.profile.referrals;

const selectHealthcareCoverages = (state: IReduxState): IStateData<IDependentAndCoverageTypes[]> =>
  state.profile.healthcareCoverages;

const selectFpcPrimaryCare = (state: IReduxState): IStateData<IPrimaryCareFpc> => state.profile.fpcPrimaryCare;

export const selectPrimaryCare = (state: IReduxState): IStateData<IPrimaryCarePerMember> => state.profile.primaryCare;

export const selectProfileData = createSelector(selectProfile, profile => profile.data);

export const selectProfileLoading = createSelector(selectProfile, profile => profile.loading);

export const selectProfileError = createSelector(selectProfile, profile => profile.error);

export const selectClaimsPreferencesData = createSelector(
  selectClaimsPreferences,
  claimsPreferences => claimsPreferences.data,
);

export const selectClaimsPreferencesLoading = createSelector(
  selectClaimsPreferences,
  claimsPreferences => claimsPreferences.loading,
);

export const selectClaimsPreferencesError = createSelector(
  selectClaimsPreferences,
  claimsPreferences => claimsPreferences.error,
);

export const selectCommunicationPreferencesData = createSelector(
  selectCommunicationPreferences,
  communicationPreferences => communicationPreferences.data,
);

export const selectCommunicationPreferencesLoading = createSelector(
  selectCommunicationPreferences,
  communicationPreferences => communicationPreferences.loading,
);

export const selectCommunicationPreferencesError = createSelector(
  selectCommunicationPreferences,
  communicationPreferences => communicationPreferences.error,
);

export const selectCurrentUser = createSelector(selectProfileData, profile => profile?.currentUser);

export const selectClientInfo = createSelector(selectProfileData, profile => profile?.clientInfo);

export const selectDependents = createSelector(selectProfileData, profile => profile?.dependents as IProfileUser[]);

const commonProfileUserSelectors = [selectCurrentUser, selectSelectedProfile].map(
  (selectProfileUser: Selector<IReduxState, IProfileUser>) => {
    const selectDependentSeqNum = createSelector(selectProfileUser, profileUser => profileUser?.dependentSeqNum);

    const selectLineOfBusiness = createSelector(selectProfileUser, profileUser => profileUser?.lineOfBusiness);

    const selectUserInfo = createSelector(selectProfileUser, profileUser => profileUser?.userInfo);

    const selectFirstName = createSelector(selectUserInfo, userInfo => userInfo?.firstName);

    const selectLastName = createSelector(selectUserInfo, userInfo => userInfo?.lastName);

    const selectHasLedgerAccess = createSelector(selectProfileUser, hasLedgerAccess);

    return {
      selectProfile: selectProfileUser,
      selectDependentSeqNum,
      selectLineOfBusiness,
      selectUserInfo,
      selectFirstName,
      selectLastName,
      selectHasLedgerAccess,
    };
  },
);

// currentUser - is the user that is logged in
// selectedUser - is the user that is selected using the account selector on the dashboard, it may be the same as the currentUser
export const [currentUser, selectedUser] = commonProfileUserSelectors;

export const selectIsCurrentUserSelectedProfile = createSelector(
  selectCurrentUser,
  selectSelectedProfile,
  (currentUser, selectedProfile) => currentUser?.dependentSeqNum === selectedProfile?.dependentSeqNum,
);

export const selectMarkAsPaid = createSelector(
  selectClaimsPreferencesData,
  claimsPreferences => claimsPreferences?.markAsPaid,
);

export const selectShowMarkAsPaidDisclaimer = createSelector(
  selectMarkAsPaid,
  markAsPaid => markAsPaid?.showDisclaimer,
);

export const selectProductsLoading = createSelector(selectProducts, products => products.loading);

export const selectProductsError = createSelector(selectProducts, products => products.error);

export const selectProductsData = createSelector(selectProducts, products => products.data);

export const selectDentalProductNetworkId = createSelector(
  selectProductsData,
  productsData => productsData?.dental?.planInformation?.networkId,
);

export const selectRallyEngageProduct = createSelector(selectProductsData, productsData => productsData?.rallyEngage);

export const selectReferralsLoading = createSelector(selectReferrals, referrals => referrals.loading);

export const selectReferralsError = createSelector(selectReferrals, referrals => referrals.error);

export const selectReferralsData = createSelector(selectReferrals, referrals => referrals.data);

export const selectHealthcareCoveragesLoading = createSelector(
  selectHealthcareCoverages,
  healthcareCoverages => healthcareCoverages.loading,
);

export const selectHealthcareCoveragesError = createSelector(
  selectHealthcareCoverages,
  healthcareCoverages => healthcareCoverages.error,
);

export const selectHealthcareCoveragesData = createSelector(
  selectHealthcareCoverages,
  healthcareCoverages => healthcareCoverages.data,
);

export const selectFpcPrimaryCareLoading = createSelector(
  selectFpcPrimaryCare,
  fpcPrimaryCare => fpcPrimaryCare.loading,
);

export const selectFpcPrimaryCareError = createSelector(selectFpcPrimaryCare, fpcPrimaryCare => fpcPrimaryCare.error);

export const selectFpcPrimaryCareData = createSelector(selectFpcPrimaryCare, fpcPrimaryCare => fpcPrimaryCare.data);

export const selectPrimaryCareLoading = createSelector(selectPrimaryCare, primaryCare => primaryCare.loading);

export const selectPrimaryCareError = createSelector(selectPrimaryCare, primaryCare => primaryCare.error);

export const selectPrimaryCareData = createSelector(selectPrimaryCare, primaryCare => primaryCare.data);

const selectFinancialPreferences = (state: IReduxState): IStateData<IFinancialPreferences> =>
  state.profile.financialPreferences;

export const selectFinancialPreferencesData = createSelector(selectFinancialPreferences, _ => _.data);

export const selectFinancialPreferencesError = createSelector(selectFinancialPreferences, _ => _.error);

export const selectFinancialPreferencesLoading = createSelector(selectFinancialPreferences, _ => _.loading);

export const selectPlanCoverages = createSelector(selectCurrentUser, user => user.planCoverages);

export const selectMemberFeatures = createSelector(selectCurrentUser, user => user.memberFeatures);

export const selectActivateEligible = createSelector(
  selectMemberFeatures,
  memberFeatures => memberFeatures?.activateEligible,
);

export const selectProfileInfoForRallyPay = (state: IReduxState): IStateData<string> =>
  state.profile.profileInfoForRallyPay;

export const selectProfileInfoForRallyPayData = createSelector(
  selectProfileInfoForRallyPay,
  profileInfoForRallyPay => profileInfoForRallyPay.data,
);

export const selectProfileInfoForRallyPayError = createSelector(
  selectProfileInfoForRallyPay,
  profileInfoForRallyPay => profileInfoForRallyPay.error,
);

export const selectProfileInfoForRallyPayLoading = createSelector(
  selectProfileInfoForRallyPay,
  profileInfoForRallyPay => profileInfoForRallyPay.loading,
);

export const selectOrderCovidTestsInfo = (state: IReduxState): IStateData<IOrderCovidTestKitInfo> =>
  state.profile.orderCovidTestsInfo;

export const selectOrderCovidTestsData = createSelector(
  selectOrderCovidTestsInfo,
  orderCovidTestKitInfo => orderCovidTestKitInfo.data,
);

export const selectAccountCards = (state: IReduxState): IStateData<IAccountCard[]> => state.profile.accountCards;

export const selectAccountCardsInfo = createSelector(selectAccountCards, selectAccountCards => selectAccountCards.data);
export const selectAccountCardsLoading = createSelector(
  selectAccountCards,
  selectAccountCards => selectAccountCards.loading,
);
export const selectAccountCardsError = createSelector(
  selectAccountCards,
  selectAccountCards => selectAccountCards.error,
);

const selectUhcRewards = (state: IReduxState): IStateData<boolean> => state.profile.uhcRewards;

export const selectUhcRewardsData = createSelector(selectUhcRewards, selectUhcRewards => selectUhcRewards.data);

export const selectUhcRewardsDataLoading = createSelector(
  selectUhcRewards,
  selectUhcRewards => selectUhcRewards.loading,
);

export const selectUhcRewardsDataError = createSelector(selectUhcRewards, selectUhcRewards => selectUhcRewards.error);

export const selectCurrentPlanYear = createSelector(selectCurrentUser, currentUser => {
  const coverages = currentUser?.planCoverages;
  const currentSystemYear = new Date().getFullYear(); // current system year
  const currentPlan = coverages.find(
    c =>
      c.coverageType === CoverageType.Medical &&
      (c.planPeriod.status === CoverageStatus.Active || c.planPeriod.status === CoverageStatus.Future),
  );
  const planYearFromProfile = getYear(currentPlan?.planPeriod?.startDate);
  if (planYearFromProfile > currentSystemYear) {
    return planYearFromProfile;
  } else {
    return currentSystemYear;
  }
});

export const selectMedicareNudgeEligibility = (state: IReduxState): IStateData<boolean> =>
  state.profile.medicareNudgeEligibility;
