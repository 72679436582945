import {
  IProfile,
  IProfileUser,
  LineOfBusiness,
  MembershipCategory,
  IPrimaryCarePerMember,
  IPrimaryCareFpc,
} from 'scripts/api/profile/profile.interfaces';
import {
  CampaignPlacementType,
  ICampaignPlacements,
  IPlanInfo,
  IRecommendationCampaign,
} from 'scripts/api/targeting/targeting.interfaces';
import {
  IAACampaignsData,
  IAAContent,
  IAADataLayer,
  IAAMemberPlan,
  IAAPostPageData,
  IAASatellite,
  ITrackingEventRequest,
  IVideoTrackingEvent,
  TrackingTriggerType,
  adobeMcParamKey,
  adobeMcTimestampKey,
} from 'scripts/api/tracking/tracking.interfaces';
import { IHeartbeat } from 'scripts/api/user/user.interfaces';
import { IPharmacyLinkCustomTrackingInfo } from 'scripts/features/pharmacy/pharmacy.interfaces';
import { getLocale } from '../locale/locale';
import { IConfig, IFeatureFlags } from '../constants/environment.interfaces';
import { IPlanBenefits } from 'scripts/api/plans/plans.interfaces';
import {
  getSelectedUserPrimaryCareProviderInfo,
  getPrimaryCareProviderStatusText,
} from 'scripts/features/beta-dashboard/pcp/pcp-strip-utils';
import {
  ISelectedUserPrimaryCareProviderInfo,
  PrimaryCareStatusText,
} from 'scripts/features/beta-dashboard/pcp/pcp-strip.interfaces';
import { IPlanTypes } from 'scripts/features/modals/id-cards/id-cards.interfaces';
import { IPopulation } from '../population/population.interfaces';
import { createScriptTag } from './tracking-helper';
import { getCoverageTypeCodesFromProfileUser } from 'scripts/util/profile/profile';
import { isMrShipNotTermed } from '../plans/plans';
import { isTermedForCoverageType, shouldHidePcp } from '../profile/profile';
import { CoverageType } from 'scripts/api/api.interfaces';
import { ITaskItem } from 'scripts/features/beta-dashboard/onboarding-tasks/tasks';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    _satellite: IAASatellite;
    publishPostPageData: (trackingName: string, pageData: IAAPostPageData) => void;
  }
}

let aaInitialized = false;

export enum AAPageVersions {
  HDHP = 'HDHP',
  Gated = 'Gated',
  NonGated = 'NonGated',
}

export enum AAPcpAssignStatuses {
  Selected = 'pcpSelected',
  NotSelected = 'pcpNotSelected',
  NotAvailable = 'pcpNotAvailable',
}

const pcpStatusMap = {
  [PrimaryCareStatusText.Assign]: AAPcpAssignStatuses.NotSelected,
  [PrimaryCareStatusText.AssignmentInProgress]: AAPcpAssignStatuses.Selected,
  [PrimaryCareStatusText.Change]: AAPcpAssignStatuses.Selected,
  [PrimaryCareStatusText.ChangeInProgress]: AAPcpAssignStatuses.Selected,
};

export function init(config: IConfig): Promise<void> {
  const MAX_WAIT = 5000;
  const INTERVAL = 50;

  const adobeAnalyticsUrl = config.ARCADE_WEB_ADOBE_ANALYTICS_URL;
  if (adobeAnalyticsUrl) {
    createScriptTag(adobeAnalyticsUrl, 'adobe-analytics');
    return new Promise(resolve => {
      let currentWait = 0;
      const adobeTryInterval = setInterval(() => {
        if (window._satellite) {
          clearInterval(adobeTryInterval);
          resolve();
        }
        if (currentWait > MAX_WAIT) {
          console.warn('Adobe analytics failed to load');
          clearInterval(adobeTryInterval);
          resolve();
        }
        currentWait += INTERVAL;
      }, INTERVAL);
    });
  } else {
    return Promise.resolve();
  }
}

export function getAASatellite(): IAASatellite {
  if (!window._satellite?.track || !window._satellite?.pageBottom) {
    return {
      track: () => console.warn('Unable to find Adobe Analytics for track method'),
      pageBottom: () => console.warn('Unable to find Adobe Analytics for pageBottom method'),
    };
  }
  return window._satellite;
}

export function sendAAPageData<T>(name: string, content: IAAContent, additionalData?: T): void {
  const data = {
    content: {
      locale: getLocale().id,
      ...content,
    },
    ...additionalData,
  };

  const tryInterval = setInterval(() => {
    if (typeof window.publishPostPageData === 'function') {
      window.publishPostPageData(name, data);
      clearInterval(tryInterval);
    }
  }, 100);
}

export function getAAPageName(): string {
  const path = window.location.pathname;
  const pathParts = path.split('/');
  let pageName = '';

  if (path.indexOf('/modal/') > -1) {
    pageName = 'modal:';
  }

  pageName = pageName + pathParts[pathParts.length - 1];
  return pageName;
}

export function getAADataLayer(): IAADataLayer {
  const pdl = 'pageDataLayer';
  window[pdl] = window[pdl] || {};
  window[pdl].content = window[pdl].content || {};
  return window[pdl];
}

export function adobeTargetCustomEvents(profile: IProfile): void {
  const notAvailable = 'n/a';
  const eventName = 'viewStart';
  // Adobe Target Custom events
  (window as any).adobeDataLayer = (window as any).adobeDataLayer || [];
  (window as any).adobeDataLayer.push({
    event: eventName,
    'page-info': {
      pageName: getAAPageName(),
      lob: profile.currentUser.lineOfBusiness,
      coverageTypeCode: getCoverageTypeCodesFromProfileUser(profile.currentUser)[0],
      govtProgramTypeCode:
        profile.currentUser.planCoverages.map(coverage => coverage.planFeatures.programType) || notAvailable,
      productCode:
        profile.currentUser.planCoverages.map(coverage => coverage.additionalCoverageInfo?.productCode) || notAvailable,
      age: profile.currentUser.userInfo.age,
      clientId: profile.currentUser.userInfo.primaryCustomerId
        ? profile.currentUser.userInfo.primaryCustomerId
        : notAvailable,
      gender: profile.currentUser.userInfo.gender,
      policyNumber: profile.currentUser.planCoverages.map(coverage => coverage.policyNumber) || notAvailable,
      groupId: profile.currentUser.primaryPolicyNumber,
    },
  });
}

/**
 * Adobe Analytics
 * This method is based off external requirements. Unfortunately, the way this is setup, it's extremely
 * prone to some pretty bad race conditions, which could render any tracked data completely useless. Every event
 * that is fired is done so asynchronously and at any given time (sometimes the same time). window._satellite
 * does not accept a data object as a parameter, but rather an object defined on global (window) scope. If two
 * events fire at the same time, it's entirely possible that one of them could override the data of the other,
 * thus corrupting what gets sent to Adobe Analytics. To fix this issue, window._satellite should accept the
 * event object as the param instead of the event type (which just so happens to exist in the event object).
 * @param event
 */
export function sendAAEvent(event: ITrackingEventRequest): void {
  try {
    const aa = getAASatellite();
    const aaDataLayer = getAADataLayer();
    // Update the expected window.pageDataLayer object that AA looks for
    aaDataLayer.content.pageName = getAAPageName();
    aaDataLayer.content.siteSectionL1 = window.location.pathname.split('/')[1];
    aaDataLayer.content.locale = getLocale().id;
    aaDataLayer.content.mostRecentEvent = {
      uri: window.location.pathname,
      featureList: event.featureList,
      action: event.actionName,
      eventType: event.trigger,
      fullName: event.featureList.concat([event.actionName]).join('.'),
    };

    // Send out an event if the action is a click or a section fail
    switch (event.trigger) {
      case TrackingTriggerType.Click:
        aaDataLayer.content.siteErrorType = null;
        aa.track('click');
        break;
      case TrackingTriggerType.SectionFailed:
        aaDataLayer.content.siteErrorType = event.actionName;
        aa.track('errorTracking');
        break;
    }
  } catch (err) {
    console.error('Error sending AA event', err);
  }
}

/**
 * Adobe Analytics
 * This method is based off external requirements. Unfortunately, the way this is setup,
 * we need to bypass the normal flow of our event structure to log impressions to Adobe.
 * @param event
 */
export function sendOutOfFlowAAEvent(event: ITrackingEventRequest): void {
  const aa = getAASatellite();
  const aaDataLayer = getAADataLayer();
  // Update the expected window.pageDataLayer object that AA looks for
  aaDataLayer.content.pageName = getAAPageName();
  aaDataLayer.content.siteSectionL1 = window.location.pathname.split('/')[1];
  aaDataLayer.content.locale = getLocale().id;
  aaDataLayer.content.mostRecentEvent = {
    uri: window.location.pathname,
    featureList: event.featureList,
    action: event.actionName,
    eventType: event.trigger,
    fullName: event.featureList.concat([event.actionName]).join('.'),
  };

  // Send out an event if the action is a click. View doesn't seem to work.
  aaDataLayer.content.siteErrorType = null;
  aa.track('click');
}

export function sendMrTrackPagesEvent(): void {
  if (getAADataLayer()?.lineOfBusiness === LineOfBusiness.MR) {
    const aa = getAASatellite();
    aa.track('mrtrackPages');
  }
}

export function sendTrackSpaPageViewEvent(): void {
  if (getAADataLayer()) {
    const aa = getAASatellite();
    const pageName = getAAPageName();
    aa.track('spaPageView', { viewName: pageName });
  }
}

export function sendAAInitEvent(): void {
  if (aaInitialized) {
    return;
  }

  const adobeTryInterval = setInterval(() => {
    if (window._satellite && window._satellite.pageBottom) {
      window._satellite.pageBottom();
      aaInitialized = true;
    }
    if (aaInitialized) {
      clearInterval(adobeTryInterval);
    }
  }, 10);
}

export function queueAAVideoEvent(data: IVideoTrackingEvent): void {
  const content = {
    pageName: getAAPageName(),
    siteSectionL1: window.location.pathname.split('/')[1],
  };

  const videoData = { videoContent: { ...data.videoData, videoPageName: getAAPageName() } };

  sendAAPageData(data.actionName, content, videoData);
}

export function sendPharmacyLinkClickAACustomEvent(customLinkTrackingInfo: IPharmacyLinkCustomTrackingInfo): void {
  const event = document.createEvent('customEvent') as CustomEvent;

  event.initCustomEvent('linkCall', true, true, {
    eventType: 'exitLinkClick',
    linkName: customLinkTrackingInfo.linkTrackingName,
    linkURL: customLinkTrackingInfo.trackingLink,
  });

  document.body.dispatchEvent(event);
}

export function sendOnboardingTasksStatusAACustomEvent(tasks: ITaskItem[]): void {
  const event = document.createEvent('customEvent') as CustomEvent;
  const contentTitles = tasks.map(task => `${task.status}: ${task.cta}`).join('|');

  event.initCustomEvent('impression', true, true, {
    contentTitles: contentTitles,
  });

  document.body.dispatchEvent(event);
}

export function sendTabLoadedEvent(): void {
  try {
    const path = window.location.pathname;
    const splitPath = path.split('/');
    const content = {
      tabName: splitPath[1],
      pageName: splitPath[splitPath.length - 1],
      path,
      locale: undefined,
    };
    sendAAPageData('topNavTabLoaded', content);
  } catch (err) {
    console.warn('Error sending AA Event: ', err);
  }
}

function getFormattedCampaignsData(
  campaigns?: ICampaignPlacements,
  incentivesPlanInfo?: IPlanInfo,
  recommendations?: IRecommendationCampaign[],
): IAACampaignsData {
  const ids = [];
  const types = [];
  const titles = [];
  const offerCodes = [];

  if (recommendations) {
    recommendations.forEach(rec => {
      ids.push(rec.campaignId);
      types.push(CampaignPlacementType.ArcadeRecommendationRto);
      titles.push(rec.headline.replace(/\|/g, ''));
      offerCodes.push(rec.offerCode);
    });
  }

  if (incentivesPlanInfo) {
    ids.push(incentivesPlanInfo.planId.replace(/\|/g, ''));
    types.push('incentives');
    titles.push(incentivesPlanInfo.planTitle.replace(/\|/g, ''));
  }

  if (campaigns) {
    const resourcesPagePlacementTypes = [
      CampaignPlacementType.ArcadeResourcesPrimaryWellness,
      CampaignPlacementType.ArcadeResourcesSecondaryWellness,
      CampaignPlacementType.ArcadeResourcesPrograms,
      CampaignPlacementType.ArcadeResourcesText,
      CampaignPlacementType.ArcadeResourcesSection1Link,
      CampaignPlacementType.ArcadeResourcesSection2Link,
      CampaignPlacementType.ArcadeResourcesGeneralPromo,
    ];

    for (const placementType of resourcesPagePlacementTypes) {
      if (campaigns[placementType]) {
        const campaignsOfType = campaigns[placementType];
        for (const campaign of campaignsOfType) {
          ids.push(campaign.campaignId);
          types.push(campaign.placementType);
          if (
            placementType === CampaignPlacementType.ArcadeResourcesSection1Link ||
            placementType === CampaignPlacementType.ArcadeResourcesSection2Link
          ) {
            titles.push(campaign.cta.ctaText.replace(/\|/g, ''));
          } else {
            titles.push(campaign.headline.replace(/\|/g, ''));
          }
        }
      }
    }
  }

  const data = {
    campaigns: {
      id: ids.join('|'),
      placement: types.join('|'),
      title: titles.join('|'),
    },
  } as IAACampaignsData;
  if (offerCodes.length > 0) {
    data.campaigns.offerCode = offerCodes.join('|');
  }

  return data;
}

export type CampaignsLoadedOptions = {
  campaigns?: ICampaignPlacements;
  incentivesPlanInfo?: IPlanInfo;
  recommendations?: IRecommendationCampaign[];
};

export function sendCampaignsLoadedEvent(options: CampaignsLoadedOptions): void {
  const { campaigns, incentivesPlanInfo, recommendations } = options;
  if (campaigns || incentivesPlanInfo || recommendations) {
    const campaignsData = getFormattedCampaignsData(campaigns, incentivesPlanInfo, recommendations);
    const path = window.location.pathname;
    const splitPath = path.split('/');
    const content = {
      tabName: splitPath[1],
      pageName: splitPath[splitPath.length - 1],
      path,
      locale: undefined,
    };
    sendAAPageData('campaignsLoaded', content, campaignsData);
  }
}

export function sendCustomImpressionEvent(promoType: string, promoTitle: string): void {
  const event = document.createEvent('CustomEvent');
  event.initCustomEvent('impressions', true, true, {
    contentTitles: `${promoType}:${promoTitle}`,
  });
  document.body.dispatchEvent(event);
}

export function setInitialAADataLayerValues(): void {
  const aaDataLayer = getAADataLayer();
  aaDataLayer.rallyId = aaDataLayer.rallyId || null;
  aaDataLayer.loginStatus = aaDataLayer.loginStatus || 'not-loggedin';
  aaDataLayer.zipcode = aaDataLayer.zipcode || 'no-zipcode';
  aaDataLayer.clientId = aaDataLayer.clientId || 'no-id';
  aaDataLayer.clientName = aaDataLayer.clientName || 'no-name';
  aaDataLayer.memberPlansInfo = aaDataLayer.memberPlansInfo || [];
}

export function setAABenefitsValues(planBenefits: IPlanBenefits): void {
  const aaDataLayer = getAADataLayer();
  if (!aaDataLayer.memberPlansInfo) {
    console.error('memberPlansInfo was not found');
    return;
  }

  aaDataLayer.memberPlansInfo = aaDataLayer.memberPlansInfo.map(plan => {
    let planName = 'n/a';
    let planType = 'n/a';
    planBenefits.benefits.forEach(benefit => {
      if (benefit.coverageTypeCode === plan.coverageTypeCode) {
        planName = benefit.planName;
        planType = IPlanTypes[benefit.coverageType];
      }
    });
    return {
      ...plan,
      planName,
      planType,
    };
  });
}

export function setAAProfileValues(heartbeat: IHeartbeat, profile: IProfile): void {
  const notAvailable = 'n/a';
  const aaDataLayer = getAADataLayer();

  aaDataLayer.groupId = profile.currentUser.primaryPolicyNumber;
  aaDataLayer.rallyId = heartbeat.rallyId;
  aaDataLayer.externalId = heartbeat.externalId;
  aaDataLayer.loginStatus = 'loggedin';
  aaDataLayer.dependentSeqNum = profile.currentUser.dependentSeqNum;
  aaDataLayer.zipcode = profile.currentUser.userInfo.zipCode ? profile.currentUser.userInfo.zipCode : notAvailable;
  aaDataLayer.clientId = profile.currentUser.userInfo.primaryCustomerId
    ? profile.currentUser.userInfo.primaryCustomerId
    : notAvailable;
  aaDataLayer.clientName = profile.clientInfo ? profile.clientInfo.displayName : notAvailable;

  aaDataLayer.memberPlansInfo = profile.currentUser.planCoverages.map(coverage => {
    return {
      aco: coverage.additionalCoverageInfo?.aco || notAvailable,
      coverageTypeCode: coverage.coverageTypeCode || notAvailable,
      fundingArrangementCode: coverage.planFeatures.fundingArrangementType || notAvailable,
      govtProgramTypeCode: coverage.planFeatures.programType || notAvailable,
      memberId: coverage.memberId.id,
      policyNumber: coverage.policyNumber,
      status: coverage.planPeriod.status,
      productCode: coverage.additionalCoverageInfo?.productCode || notAvailable,
    } as IAAMemberPlan;
  });

  aaDataLayer.lineOfBusiness = profile.currentUser.lineOfBusiness;
  if (profile.currentUser.membershipCategory) {
    aaDataLayer.membershipCategory = profile.currentUser.membershipCategory;
  }

  aaDataLayer.userFeedback = {
    clientId: aaDataLayer.clientId,
    depSeqNumber: aaDataLayer.dependentSeqNum,
    lob: aaDataLayer.lineOfBusiness,
    memberId: aaDataLayer.externalId,
    memberType: aaDataLayer.membershipCategory,
    path: window.location.pathname,
    policyNumber: aaDataLayer.groupId,
    rallyId: aaDataLayer.rallyId,
    status: aaDataLayer.memberStatus,
    age: profile.currentUser.userInfo.age,
    gender: profile.currentUser.userInfo.gender,
  };
}

export function setAALineOfBusinessValues(
  lineOfBusiness: LineOfBusiness,
  membershipCategory: MembershipCategory,
): void {
  const aaDataLayer = getAADataLayer();
  aaDataLayer.lineOfBusiness = lineOfBusiness;
  if (membershipCategory) {
    aaDataLayer.membershipCategory = membershipCategory;
  }
}

export function setAAPageName(): void {
  const aaDataLayer = getAADataLayer();
  aaDataLayer.content.pageName = getAAPageName();
}

export function setAAUserFeedbackPath(): void {
  const pdl = getAADataLayer();
  if (pdl.userFeedback) {
    pdl.userFeedback.path = window.location.pathname;
  }
}

export function setAAHeartbeatValues(heartbeat: IHeartbeat): void {
  const aaDataLayer = getAADataLayer();
  aaDataLayer.rallyId = heartbeat.rallyId;
  aaDataLayer.externalId = heartbeat.externalId;
}

export function setAAPopulationValues(population: IPopulation): void {
  const aaDataLayer = getAADataLayer();
  aaDataLayer.lineOfBusiness = population.lineOfBusiness;
  if (population.membershipCategory) {
    aaDataLayer.membershipCategory = population.membershipCategory;
  }
}

// setAAPageVersionName sets page version name using the following ruleset:
// 1. if DEDUCTIBLE_MESSAGE component is present, value is "HDHP"
// 2. else if PROVIDERS_AND_FACILITIES component is present, value is "Gated"
// 3. otherwise, value is NonGated
export function setAAPageVersionName(selectedProfile: IProfileUser): void {
  const aaDataLayer = getAADataLayer();
  aaDataLayer.content.pageVersionName = AAPageVersions.NonGated;
  if (selectedProfile?.memberFeatures?.pcpEligible || selectedProfile?.memberFeatures?.fpcEligible) {
    aaDataLayer.content.pageVersionName = AAPageVersions.Gated;
  }
}

// setAAPcpAssignStatus sets pcpAssignStatus using the following ruleset:
// 1. pcpAssignStatus value is pcpSelected if a user has a pcpAssigned
//    (PROVIDERS_AND_FACILITIES component is present and user has an active Medical pcp assigned)
// 2. otherwise, value is pcpNotSelected
export const setAAPcpAssignStatus =
  (featureFlags: IFeatureFlags) =>
  (
    selectedProfile: IProfileUser,
    primaryCare: IPrimaryCarePerMember,
    fpcPrimaryCare: IPrimaryCareFpc,
    config: IConfig,
  ): void => {
    let primaryCareProviderStatusText: string;
    let selectedUserPrimaryCareProviderInfo: ISelectedUserPrimaryCareProviderInfo;
    const showCSCareTeam =
      !isTermedForCoverageType(CoverageType.Medical, selectedProfile.planCoverages) &&
      !shouldHidePcp(selectedProfile, config);

    const showMRCareTeam =
      !isTermedForCoverageType(CoverageType.Medical, selectedProfile.planCoverages) &&
      !shouldHidePcp(selectedProfile, config) &&
      !isMrShipNotTermed(selectedProfile);

    const pcpComponentPresent = (selectedUser: IProfileUser): boolean => {
      switch (selectedUser?.lineOfBusiness) {
        case LineOfBusiness.CS:
          return showCSCareTeam;
        case LineOfBusiness.EI:
          return true;
        case LineOfBusiness.MR:
          return showMRCareTeam;
        default:
          return false;
      }
    };

    try {
      selectedUserPrimaryCareProviderInfo = getSelectedUserPrimaryCareProviderInfo(featureFlags)(
        selectedProfile,
        primaryCare,
        fpcPrimaryCare,
      );
      primaryCareProviderStatusText =
        selectedUserPrimaryCareProviderInfo && getPrimaryCareProviderStatusText(selectedUserPrimaryCareProviderInfo);
    } catch (e) {
      // functions could fail due to various args being undefined.
      // selectedUserPrimaryCareProviderInfo will remain undefined here and result in pcpAssignStatus
      // being set to NotAvailable/NotSelected (depending on whether or not component is present) below.
    }

    let pcpAssignStatus = AAPcpAssignStatuses.NotAvailable;
    if (pcpComponentPresent(selectedProfile)) {
      // if component is present, status is not selected by default
      pcpAssignStatus = pcpStatusMap[primaryCareProviderStatusText] || AAPcpAssignStatuses.NotSelected;
    }
    const aaDataLayer = getAADataLayer();
    aaDataLayer.content.pcpAssignStatus = pcpAssignStatus;
  };

// setAAImpressionTitles sets the impressionTitles using the following ruleset:
// 1. set the currently displayed recommendation headline as the impressionTitles
export function setAAImpressionTitles(recommendationsHeadline: string): void {
  const aaDataLayer = getAADataLayer();
  aaDataLayer.content.impressionTitles = recommendationsHeadline;
}

export function storeAdobeMcParam(adobeMcParamValue: string): void {
  // Prune the time stamp since we'll need to update it before using
  const prunedAdobeMcParamValue = adobeMcParamValue.slice(0, adobeMcParamValue.indexOf(adobeMcTimestampKey));

  localStorage.setItem(adobeMcParamKey, prunedAdobeMcParamValue);
}

export function addAdobeTrackingParam(url: string): string {
  const adobeMcParamValue = localStorage.getItem(adobeMcParamKey);

  if (!adobeMcParamValue || url.includes(adobeMcParamKey)) {
    return url;
  }

  const timeStamp = Date.now();

  const adobeParam = `${adobeMcParamKey}=${adobeMcParamValue}${adobeMcTimestampKey}=${timeStamp}`;

  const urlWithAdobeParam = url.includes('?') ? `${url}&${adobeParam}` : `${url}?${adobeParam}`;

  return urlWithAdobeParam;
}
