/* eslint-disable no-restricted-imports */
import * as DateFns from 'date-fns';
export * from 'date-fns';

/**
 * This file exists to extend the functionality of date-fns. Most of our use cases for dates
 * start with parsing an ISO string that we receive from an API. So we have extended the
 * date-fns functions to accept an ISO string as well. This prevents us from having to
 * constantly call parseISO. If there is a function missing from this file that exists in
 * date-fns that you would like to support an ISO string, feel free to add it.
 */

export type DateInput = string | number | Date;

const getDateObj = (date: DateInput = new Date()): Date | number => {
  if (typeof date === 'string') {
    return DateFns.parseISO(date);
  }
  return date;
};

type DateFnsFormatParams = Parameters<typeof DateFns.format>;

export const format = (
  date: DateInput,
  format: DateFnsFormatParams[1] = 'yyyy-MM-dd',
  options?: DateFnsFormatParams[2],
): ReturnType<typeof DateFns.format> => {
  return DateFns.format(getDateObj(date), format, options);
};

export const compareDesc = (dateLeft: DateInput, dateRight: DateInput): ReturnType<typeof DateFns.compareDesc> => {
  return DateFns.compareDesc(getDateObj(dateLeft), getDateObj(dateRight));
};

export const compareAsc = (dateLeft: DateInput, dateRight: DateInput): ReturnType<typeof DateFns.compareAsc> => {
  return DateFns.compareAsc(getDateObj(dateLeft), getDateObj(dateRight));
};

export const getYear = (date: DateInput): ReturnType<typeof DateFns.getYear> => {
  return DateFns.getYear(getDateObj(date));
};

export const getMonth = (date: DateInput): ReturnType<typeof DateFns.getMonth> => {
  return DateFns.getMonth(getDateObj(date));
};

export const getDate = (date: DateInput): ReturnType<typeof DateFns.getDate> => {
  return DateFns.getDate(getDateObj(date));
};

export const setYear = (date: DateInput, year: number): ReturnType<typeof DateFns.setYear> => {
  return DateFns.setYear(getDateObj(date), year);
};

export const setMonth = (date: DateInput, month: number): ReturnType<typeof DateFns.setMonth> => {
  return DateFns.setMonth(getDateObj(date), month);
};

export const setDate = (date: DateInput, dayOfMonth: number): ReturnType<typeof DateFns.setDate> => {
  return DateFns.setDate(getDateObj(date), dayOfMonth);
};

export const isValid = (date: DateInput): ReturnType<typeof DateFns.isValid> => {
  return DateFns.isValid(getDateObj(date));
};

export const isBefore = (date: DateInput, dateToCompare: DateInput): ReturnType<typeof DateFns.isBefore> => {
  return DateFns.isBefore(getDateObj(date), getDateObj(dateToCompare));
};

export const isAfter = (date: DateInput, dateToCompare: DateInput): ReturnType<typeof DateFns.isAfter> => {
  return DateFns.isAfter(getDateObj(date), getDateObj(dateToCompare));
};

export const isSameDay = (date: DateInput, dateToCompare: DateInput): ReturnType<typeof DateFns.isSameDay> => {
  return DateFns.isSameDay(getDateObj(date), getDateObj(dateToCompare));
};

export const isSameMonth = (date: DateInput, dateToCompare: DateInput): ReturnType<typeof DateFns.isSameMonth> => {
  return DateFns.isSameMonth(getDateObj(date), getDateObj(dateToCompare));
};

export const isSameYear = (date: DateInput, dateToCompare: DateInput): ReturnType<typeof DateFns.isSameYear> => {
  return DateFns.isSameYear(getDateObj(date), getDateObj(dateToCompare));
};

export const isSameDayOrAfter = (date: DateInput, dateToCompare: DateInput): boolean => {
  return isSameDay(date, dateToCompare) || isAfter(date, dateToCompare);
};

export const isSameMonthOrAfter = (date: DateInput, dateToCompare: DateInput): boolean => {
  return isSameMonth(date, dateToCompare) || isAfter(date, dateToCompare);
};

export const isSameYearOrAfter = (date: DateInput, dateToCompare: DateInput): boolean => {
  return isSameYear(date, dateToCompare) || isAfter(date, dateToCompare);
};

export const isSameDayOrBefore = (date: DateInput, dateToCompare: DateInput): boolean => {
  return isSameDay(date, dateToCompare) || isBefore(date, dateToCompare);
};

export const isSameMonthOrBefore = (date: DateInput, dateToCompare: DateInput): boolean => {
  return isSameMonth(date, dateToCompare) || isBefore(date, dateToCompare);
};

export const isSameYearOrBefore = (date: DateInput, dateToCompare: DateInput): boolean => {
  return isSameYear(date, dateToCompare) || isBefore(date, dateToCompare);
};

export const differenceInYears = (
  dateLeft: DateInput,
  dateRight: DateInput,
): ReturnType<typeof DateFns.differenceInYears> => {
  return DateFns.differenceInYears(getDateObj(dateLeft), getDateObj(dateRight));
};

export const differenceInMonths = (
  dateLeft: DateInput,
  dateRight: DateInput,
): ReturnType<typeof DateFns.differenceInMonths> => {
  return DateFns.differenceInMonths(getDateObj(dateLeft), getDateObj(dateRight));
};

export const differenceInDays = (
  dateLeft: DateInput,
  dateRight: DateInput,
): ReturnType<typeof DateFns.differenceInDays> => {
  return DateFns.differenceInDays(getDateObj(dateLeft), getDateObj(dateRight));
};

export const addDays = (date: DateInput, amount: number): ReturnType<typeof DateFns.addDays> => {
  return DateFns.addDays(getDateObj(date), amount);
};

export const addMonths = (date: DateInput, amount: number): ReturnType<typeof DateFns.addMonths> => {
  return DateFns.addMonths(getDateObj(date), amount);
};

export const addYears = (date: DateInput, amount: number): ReturnType<typeof DateFns.addYears> => {
  return DateFns.addYears(getDateObj(date), amount);
};

export const subDays = (date: DateInput, amount: number): ReturnType<typeof DateFns.subDays> => {
  return DateFns.subDays(getDateObj(date), amount);
};

export const subMonths = (date: DateInput, amount: number): ReturnType<typeof DateFns.subMonths> => {
  return DateFns.subMonths(getDateObj(date), amount);
};

export const subYears = (date: DateInput, amount: number): ReturnType<typeof DateFns.subYears> => {
  return DateFns.subYears(getDateObj(date), amount);
};

export const getDaysInMonth = (date: DateInput): ReturnType<typeof DateFns.getDaysInMonth> => {
  return DateFns.getDaysInMonth(getDateObj(date));
};

export const hoursToMilliseconds = (hours: number): ReturnType<typeof DateFns.hoursToMilliseconds> => {
  return DateFns.hoursToMilliseconds(hours);
};

export const max = (datesArray: DateInput[]): ReturnType<typeof DateFns.max> => {
  return DateFns.max(datesArray.map(date => getDateObj(date)));
};

export const startOfDay = (date: DateInput): ReturnType<typeof DateFns.startOfDay> => {
  return DateFns.startOfDay(getDateObj(date));
};
// Custom Functions

export const formatTime = (time: string, formattingOptions: string = 'h:mm a'): ReturnType<typeof DateFns.format> => {
  const today = new Date();
  const isPm = time.toLowerCase().includes('pm');
  const isAm = time.toLowerCase().includes('am');

  if (!isPm && !isAm) {
    throw new Error('Missing AM or PM');
  }

  const timeWithoutAmPm = time.replace(/am|pm/gi, '').trim();

  const [hours, minutes, seconds] = timeWithoutAmPm.split(':').map(Number);
  if (hours < 0 || hours > 12 || minutes < 0 || minutes > 59 || seconds < 0 || seconds > 59) {
    throw new Error('Invalid time');
  }

  let adjustedHours = hours;

  // adjusting hours for 24 hour time
  if (hours === 12) {
    adjustedHours = 0;
  }
  if (isPm) {
    adjustedHours = adjustedHours + 12;
  }

  if (adjustedHours !== undefined) {
    today.setHours(adjustedHours);
  }
  if (minutes !== undefined) {
    today.setMinutes(minutes);
  }
  if (seconds !== undefined) {
    today.setSeconds(seconds);
  }

  return DateFns.format(today, formattingOptions);
};
