import { Dictionary } from 'scripts/util/constants/i18n.constants';
import * as AccountInfo from 'i18n/en-US/account-info.json';
import * as AccountSummary from 'i18n/en-US/account-summary.json';
import * as Accumulators from 'i18n/en-US/accumulators.json';
import * as ActivateVideo from 'i18n/en-US/activate-video.json';
import * as ActivateVideoTranscript from 'i18n/en-US/activate-video-transcript.json';
import * as BenefitsOverview from 'i18n/en-US/benefits-overview.json';
import * as Claim from 'i18n/en-US/claim.json';
import * as ClaimBalances from 'i18n/en-US/claim-balances.json';
import * as Common from 'i18n/en-US/common.json';
import * as CompareCare from 'i18n/en-US/compare-care.json';
import * as ContactUs from 'i18n/en-US/contact-us.json';
import * as Coronavirus from 'i18n/en-US/coronavirus.json';
import * as Costs from 'i18n/en-US/costs.json';
import * as Errors from 'i18n/en-US/errors.json';
import * as Faq from 'i18n/en-US/faq.json';
import * as IdCards from 'i18n/en-US/id-cards.json';
import * as Inactive from 'i18n/en-US/inactive.json';
import * as MedicareEligibility from 'i18n/en-US/medicare-eligibility.json';
import * as MailingSummaryModal from 'i18n/en-US/mailing-summary-modal.json';
import * as Notification from 'i18n/en-US/notification.json';
import * as Onboarding from 'i18n/en-US/onboarding.json';
import * as PageTitles from 'i18n/en-US/page-titles.json';
import * as PayNowModal from 'i18n/en-US/pay-now-modal.json';
import * as Pcp from 'i18n/en-US/pcp.json';
import * as Pharmacy from 'i18n/en-US/pharmacy.json';
import * as PremiumPayments from 'i18n/en-US/premium-payments.json';
import * as prescriptions from 'i18n/en-US/prescriptions.json';
import * as Programs from 'i18n/en-US/programs.json';
import * as Recommendations from 'i18n/en-US/recommendations.json';
import * as Resources from 'i18n/en-US/resources.json';
import * as SeeYouLater from 'i18n/en-US/see-you-later.json';
import * as SuperUserBanner from 'i18n/en-US/super-user-banner.json';
import * as Wellness from 'i18n/en-US/wellness.json';
import * as WaysToSave from 'i18n/en-US/ways-to-save.json';
import * as RewardsWidgets from 'i18n/en-US/rewards-widgets.json';
import * as IDCardAccessibility from 'i18n/en-US/id-card-accessibility.json';
import * as SAMBanner from 'i18n/en-US/sam-banner.json';

// This is a mapping of a dictionary to the json file with the translation keys and values.
// We use these to prime the cache of our translation libraries so we get a better user experience
// on startup. If you are adding a new dictionary please add the mapping to the json file here. We
// have a test that will fail otherwise.
export const resources: [Dictionary, any][] = [
  [Dictionary.ACCOUNT_INFO, AccountInfo],
  [Dictionary.ACCOUNT_SUMMARY, AccountSummary],
  [Dictionary.ACCUMULATORS, Accumulators],
  [Dictionary.ACTIVATE_VIDEO, ActivateVideo],
  [Dictionary.ACTIVATE_VIDEO_TRANSCRIPT, ActivateVideoTranscript],
  [Dictionary.BENEFITS_OVERVIEW, BenefitsOverview],
  [Dictionary.CLAIM, Claim],
  [Dictionary.CLAIM_BALANCES, ClaimBalances],
  [Dictionary.COMMON, Common],
  [Dictionary.COMPARE_CARE, CompareCare],
  [Dictionary.CONTACT_US, ContactUs],
  [Dictionary.CORONAVIRUS, Coronavirus],
  [Dictionary.COSTS, Costs],
  [Dictionary.ERRORS, Errors],
  [Dictionary.FAQ, Faq],
  [Dictionary.ID_CARDS, IdCards],
  [Dictionary.INACTIVE, Inactive],
  [Dictionary.MEDICARE_ELIGIBILITY, MedicareEligibility],
  [Dictionary.MAILING_SUMMARY_MODAL, MailingSummaryModal],
  [Dictionary.NOTIFICATION, Notification],
  [Dictionary.ONBOARDING, Onboarding],
  [Dictionary.PAGE_TITLES, PageTitles],
  [Dictionary.PAY_NOW_MODAL, PayNowModal],
  [Dictionary.PCP, Pcp],
  [Dictionary.PHARMACY, Pharmacy],
  [Dictionary.PREMIUM_PAYMENTS, PremiumPayments],
  [Dictionary.PRESCRIPTIONS, prescriptions],
  [Dictionary.PROGRAMS, Programs],
  [Dictionary.RECOMMENDATIONS, Recommendations],
  [Dictionary.RESOURCES, Resources],
  [Dictionary.SEE_YOU_LATER, SeeYouLater],
  [Dictionary.SUPER_USER_BANNER, SuperUserBanner],
  [Dictionary.WELLNESS, Wellness],
  [Dictionary.WAYS_TO_SAVE, WaysToSave],
  [Dictionary.REWARDS_WIDGETS, RewardsWidgets],
  [Dictionary.ID_CARD_ACCESSIBILITY, IDCardAccessibility],
  [Dictionary.SAM_BANNER, SAMBanner],
];
