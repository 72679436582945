import {
  IClaimsPreferences,
  IClaimsPreferencesResponse,
  ICommunicationPreferencesResponse,
  IFinancialPreferencesResponse,
  IEftPreferences,
  IGenoaTokenResponse,
  IHealthcareCoveragesResponse,
  IInstamedMobileTokenResponse,
  IMedicareNudgeEligibilityResponse,
  IProductsResponse,
  IProfileInfoForRallyPayResponse,
  IProfileResponse,
  IProviderVideoKeyResponse,
  IPrimaryCareFpcResponse,
  IPrimaryCareResponse,
  IReferralsResponse,
  LineOfBusiness,
  MembershipCategory,
  IOrderCovidTestKitTokenResponse,
  IAccountCardsResponse,
  IUhcRewardsResponse,
} from './profile.interfaces';
import { CacheName, IEmptyResponse } from '../api.interfaces';
import HttpClient from '../http-client';
import { getCache } from 'scripts/api/cache';
import { profileUris } from 'scripts/util/uri/uri';
import { IClaim, IHealthcareClaimDetails } from 'scripts/api/claims/claims.interfaces';
import FEATURE_FLAGS from 'scripts/util/constants/feature-flag';
import { format } from 'scripts/util/date/date';

function getProfile(rallyId: string, updateSilhouette?: boolean): Promise<IProfileResponse> {
  const url = profileUris.profile(rallyId, { updateSilhouette });
  return HttpClient.get<IProfileResponse>(url, { cacheName: CacheName.Profile }).then(rsp => {
    // We need rallyId whenever we set the currently viewed profile, so we set it equal to the rallyId from the
    // profile response if it doesn't exist on the targeted profile.
    rsp.data.currentUser.rallyId = rsp.data.currentUser.rallyId ? rsp.data.currentUser.rallyId : rsp.data.rallyId;
    if (rsp.data.dependents) {
      rsp.data.dependents.forEach(dependent => {
        dependent.rallyId = dependent.rallyId ? dependent.rallyId : rsp.data.rallyId;
      });
    }
    const coverages = rsp.data.currentUser.planCoverages;
    if (coverages) {
      rsp.data.currentUser.acos = coverages
        .map(coverage => coverage.additionalCoverageInfo && coverage.additionalCoverageInfo.aco)
        .filter(aco => !!aco);
    }

    return rsp;
  });
}

function getClaimsPreferences(rallyId: string): Promise<IClaimsPreferencesResponse> {
  const url = profileUris.claimsPreferences(rallyId);
  return HttpClient.get<IClaimsPreferencesResponse>(url, { cacheName: CacheName.ProfilePreferences });
}

function setClaimsPreferences(rallyId: string, preferences: IClaimsPreferences): Promise<IEmptyResponse> {
  const url = profileUris.claimsPreferences(rallyId);
  return HttpClient.post<IEmptyResponse>(url, preferences).then(rsp => {
    getCache(CacheName.ProfilePreferences).removeAll();
    return rsp;
  });
}

function getCommunicationPreferences(rallyId: string): Promise<ICommunicationPreferencesResponse> {
  const url = profileUris.communicationPreferences(rallyId);
  return HttpClient.get<ICommunicationPreferencesResponse>(url);
}

function getFpcPrimaryCare(
  rallyId: string,
  lineOfBusiness: LineOfBusiness,
  membershipCategory: MembershipCategory,
  selectPcpEligible: boolean,
  depSeqNum: string,
): Promise<IPrimaryCareFpcResponse> {
  const params = { lineOfBusiness, membershipCategory, selectPcpEligible, d: depSeqNum };
  const url = profileUris.fpcPrimaryCare(rallyId, params);
  return HttpClient.get<IPrimaryCareFpcResponse>(url, { cacheName: CacheName.FpcPrimaryCareInformation });
}

function getInstamedMobileToken(
  rallyId: string,
  claimPayKey: string,
  returnUrl: string,
): Promise<IInstamedMobileTokenResponse> {
  const url = profileUris.instamedMobileToken(rallyId, { instamedPacket: claimPayKey, callBackUrl: returnUrl });
  return HttpClient.get<IInstamedMobileTokenResponse>(url, { cacheName: CacheName.Profile });
}

function getPrimaryCare(rallyId: string, isPcdEligible?: boolean): Promise<IPrimaryCareResponse> {
  const params = { isPcdEligible };
  const url = profileUris.primaryCare(rallyId, params);
  return HttpClient.get<IPrimaryCareResponse>(url, { cacheName: CacheName.Profile });
}

function getProducts(rallyId: string): Promise<IProductsResponse> {
  const url = FEATURE_FLAGS.ARCADE_FEATURES_RALLY_PRODUCTS_API_V2
    ? profileUris.productsV2(rallyId)
    : profileUris.products(rallyId);
  return HttpClient.get<IProductsResponse>(url, { cacheName: CacheName.Profile });
}

function getReferrals(rallyId: string): Promise<IReferralsResponse> {
  const url = profileUris.referrals(rallyId);
  return HttpClient.get<IReferralsResponse>(url, { cacheName: CacheName.Profile });
}

function getHealthcareCoverages(rallyId: string, showCarveoutClaims: boolean): Promise<IHealthcareCoveragesResponse> {
  const url = profileUris.healthcareCoverages(rallyId, { showCarveoutClaims });
  return HttpClient.get<IHealthcareCoveragesResponse>(url, { cacheName: CacheName.Profile });
}

function getFinancialPreferences(rallyId: string): Promise<IFinancialPreferencesResponse> {
  const url = profileUris.financialPreferences(rallyId);
  return HttpClient.get<IFinancialPreferencesResponse>(url, { cacheName: CacheName.ProfilePreferences });
}

function setEftPreferences(rallyId: string, eftPreferences: IEftPreferences): Promise<IEmptyResponse> {
  const url = profileUris.eftPreferences(rallyId);
  return HttpClient.post<IEmptyResponse>(url, eftPreferences).then(rsp => {
    getCache(CacheName.ProfilePreferences).removeAll();
    return rsp;
  });
}

function getActivateProviderVideoKey(rallyId: string, params: any): Promise<IProviderVideoKeyResponse> {
  const url = profileUris.activateProviderVideoKey(rallyId, params);
  return HttpClient.get<IProviderVideoKeyResponse>(url, { cacheName: CacheName.Profile });
}

function getProfileInfoForRallyPay(
  rallyId: string,
  claim: IClaim | IHealthcareClaimDetails,
): Promise<IProfileInfoForRallyPayResponse> {
  const serviceStartDate =
    (claim as IClaim).serviceDate || (claim as IHealthcareClaimDetails).claimDates.serviceStartDate;
  const formattedServiceStartDate = format(serviceStartDate, 'yyyy-MM-dd');
  const url = profileUris.profileInfoForRallyPayV3(rallyId, formattedServiceStartDate);
  return HttpClient.get<IProfileInfoForRallyPayResponse>(url);
}

function getGenoaToken(rallyId: string): Promise<IGenoaTokenResponse> {
  const url = profileUris.genoaToken(rallyId);
  return HttpClient.get<IGenoaTokenResponse>(url);
}

function getOrderCovidTestKitToken(): Promise<IOrderCovidTestKitTokenResponse> {
  const url = profileUris.orderCovidTestKitToken();
  return HttpClient.get<IOrderCovidTestKitTokenResponse>(url);
}

function getAccountCards(): Promise<IAccountCardsResponse> {
  const url = profileUris.accountCards();
  return HttpClient.get<IAccountCardsResponse>(url);
}

function getUhcRewards(): Promise<IUhcRewardsResponse> {
  const url = profileUris.uhcRewards();
  return HttpClient.get<IUhcRewardsResponse>(url);
}

function setPcpSchedulingAuth(body): Promise<any> {
  const url = profileUris.pcpSchedulingAuth();
  return HttpClient.post(url, body);
}

function getMedicareNudgeEligibility(policyNumber: string): Promise<IMedicareNudgeEligibilityResponse> {
  const url = profileUris.medicareNudgeEligibility(policyNumber);
  return HttpClient.get<IMedicareNudgeEligibilityResponse>(url);
}

export const ProfileApi = {
  getAccountCards,
  getActivateProviderVideoKey,
  getClaimsPreferences,
  getCommunicationPreferences,
  getFinancialPreferences,
  getFpcPrimaryCare,
  getGenoaToken,
  getHealthcareCoverages,
  getInstamedMobileToken,
  getMedicareNudgeEligibility,
  getOrderCovidTestKitToken,
  getPrimaryCare,
  getProducts,
  getProfile,
  getProfileInfoForRallyPay,
  getReferrals,
  getUhcRewards,
  setClaimsPreferences,
  setEftPreferences,
  setPcpSchedulingAuth,
};
