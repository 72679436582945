import React, { FunctionComponent, useEffect } from 'react';
import { Feature } from 'scripts/ui/feature/feature';
import { trackFeatures } from 'scripts/util/constants/track-features';
import { connect, useSelector } from 'react-redux';
import { ArcadeWebTheme } from 'scripts/util/constants/environment.interfaces';
import {
  selectConfig,
  selectDashboardReadyToDisplay,
  selectFeatureFlags,
  selectThemeName,
} from 'scripts/selectors/app-selectors';
import { IReduxState } from 'scripts/reducers/reducer.interfaces';
import { ErrorBoundary } from 'scripts/ui/error-boundary/error-boundary';
import { useRouter } from 'scripts/hooks/use-router/use-router';
import { useLivePerson } from 'scripts/hooks/use-live-person/use-live-person';
import { selectCurrentUser } from 'scripts/selectors/profile-service-selectors';
import useGlobalNav from 'scripts/hooks/use-global-nav/use-global-nav';
import { initGlobalNav } from 'scripts/util/global-nav/init-global-nav';
import { HeaderContainer } from 'scripts/features/header-container/header-container';
import { FooterContainer } from 'scripts/features/footer-container/footer-container';
import styled from 'styled-components';
import { usePcpScheduling } from 'scripts/hooks/use-pcp-scheduling/use-pcp-scheduling';
import { isCS } from 'scripts/util/user/user';
import { injectTransperfectScript } from 'scripts/util/transperfect/injectTransperfectScript';

interface IChromeContainerStateToProps {
  themeName: ArcadeWebTheme;
  readyToDisplay: boolean;
}

export interface IGlobalNavContainer {
  readyToDisplay: boolean;
  isDashboard: boolean;
}

export interface IChromeContainerProps extends IChromeContainerStateToProps {}

export const RawChromeContainer: FunctionComponent<IChromeContainerProps> = props => {
  const { children, readyToDisplay } = props;
  const { pageName, location } = useRouter();
  const isDashboard = pageName === 'Dashboard';
  const currentUser = useSelector(selectCurrentUser);
  const shouldUseGlobalNav = useGlobalNav();
  const config = useSelector(selectConfig);
  const shouldUseTransperfectScript =
    useSelector(selectFeatureFlags).ARCADE_FEATURES_TRANSPERFECT_TRANSLATION_CNS_ENABLED;
  const transperfectScript = useSelector(selectConfig).ARCADE_WEB_CS_TRANSPERFECT_SCRIPT_URL;
  const isCs = isCS(currentUser);

  useLivePerson();
  usePcpScheduling();

  useEffect(() => {
    // useGlobalNav() has checks to ensure currentUser exists
    if (shouldUseGlobalNav) {
      initGlobalNav(currentUser, config);
    }
  }, [shouldUseGlobalNav]);

  useEffect(() => {
    if (shouldUseTransperfectScript && transperfectScript && isCs) {
      injectTransperfectScript(transperfectScript);
    }
  }, [shouldUseTransperfectScript, transperfectScript, isCs]);

  // Added restrictHeaderFooter to avoid loading of header footer when the user signs out
  const restrictHeaderFooter = location.pathname.includes('/logout');

  return (
    <>
      {!restrictHeaderFooter && <HeaderContainer readyToDisplay={readyToDisplay} isDashboard={isDashboard} />}
      <Feature featureId={trackFeatures.dashboard}>
        <$BeforeMainContent tabIndex={-1} id="main-content">
          <ErrorBoundary>
            <div className="possible-first-main-focus first-main-item" tabIndex={-1}></div>
            {children}
          </ErrorBoundary>
        </$BeforeMainContent>
      </Feature>
      {!restrictHeaderFooter && <FooterContainer readyToDisplay={readyToDisplay} isDashboard={isDashboard} />}
    </>
  );
};

const mapStateToProps = (state: IReduxState): IChromeContainerStateToProps => ({
  themeName: selectThemeName(state),
  readyToDisplay: selectDashboardReadyToDisplay(state),
});

export const ChromeContainer = connect(mapStateToProps, {})(RawChromeContainer);

const $BeforeMainContent = styled.main`
  height: 100%;
  position: relative;
  scroll-margin-top: 48px !important;
  width: 100%;
`;
