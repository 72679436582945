import { v4 as uuidv4 } from 'uuid';
import { PageTags as Tags, IArcadeStateData } from 'scripts/api/tracking/tracking.interfaces';

export interface IPageData extends IArcadeStateData {
  path?: string;
  isFPC?: boolean;
}

type PageKeys =
  | 'login'
  | 'logout'
  | 'directSsoGenoa'
  | 'overviewVideo'
  | 'overviewTranscript'
  | 'coronavirus'
  | 'healthResources'
  | 'optumVirtualCare'
  | 'internalError'
  | 'unauthorizedError'
  | 'seeYouLater'
  | 'liveChat'
  | 'inactive'
  | 'payNow'
  | 'internalRedirect'
  | 'dashboard'
  | 'accountSummaryExplanation'
  | 'pcpChangeInProgress'
  | 'callToChangePcp'
  | 'idCards'
  | 'pcpChangeUnavailable'
  | 'waysToSave'
  | 'waysToSaveModal'
  | 'findCare'
  | 'ovc'
  | 'premiumPayments'
  | 'pharmacy'
  | 'recommendations'
  | 'help'
  | 'pcpReferrals'
  | 'idCardspaperlessPreferences'
  | 'idCardAccessibility'
  | 'orderCovidTests'
  | 'mrNudgeModal';

export const PagesDataWithoutUUID: Record<PageKeys, Omit<IPageData, 'uuid'>> = {
  login: {
    name: 'login',
    path: '/login',
    title: 'T_LOGIN',
  },
  logout: {
    name: 'logout',
    path: '/logout',
    title: 'T_LOGOUT',
  },
  directSsoGenoa: {
    name: 'DirectSsoGenoa',
    path: '/directsso/genoa',
    title: '',
  },
  overviewVideo: {
    name: 'WatchOverviewVideo',
    path: '/modal/overview-video',
    title: 'T_OVERVIEW_VIDEO',
  },
  overviewTranscript: {
    name: 'OverviewTranscript',
    path: '/modal/overview-video/transcript',
    title: 'T_OVERVIEW_VIDEO',
  },
  coronavirus: {
    name: 'CoronavirusResources',
    path: '/coronavirus',
    tags: [Tags.healthResources],
    title: 'T_CORONAVIRUS',
  },
  healthResources: {
    name: 'HealthResources',
    path: '/health-resources',
    tags: [Tags.healthResources],
    title: 'T_HEALTH_RESOURCES',
  },
  optumVirtualCare: {
    name: 'OptumVirtualCare',
    path: '/optumvirtualcare',
    title: 'T_OPTUM_VIRTUAL_CARE',
  },
  internalError: {
    name: 'InternalError',
    path: '/internal-error',
    tags: [Tags.error],
    title: 'T_INTERNAL_ERROR',
  },
  internalRedirect: {
    name: 'InternalRedirect',
    path: '/internal-redirect',
    title: 'T_REDIRECT',
  },
  unauthorizedError: {
    name: 'UnauthorizedError',
    path: '/unauthorized-error',
    tags: [Tags.error],
    title: 'T_UNAUTHORIZED_ERROR',
  },
  dashboard: {
    trackingKeys: ['viewRecommendationsShown', 'employerRewardsShown', 'pcpStripShown'],
    name: 'Dashboard',
    path: '/dashboard',
    tags: [Tags.dashboard],
    title: 'T_HOME',
    useSelectedProfile: true,
  },
  seeYouLater: {
    name: 'SeeYouLaterExternalNav',
    path: '/see-you-later',
    title: 'T_SEE_YOU_LATER',
  },
  liveChat: {
    name: 'LiveChat',
    path: '/chat',
    title: 'T_LIVE_CHAT',
  },
  inactive: {
    name: 'SessionTimeoutWarning',
    path: '/modal/inactive',
    title: 'T_INACTIVE',
  },
  accountSummaryExplanation: {
    name: 'MedicalDeductible&OopMaxExplanation',
    path: '/modal/account-summary-explanation',
    tags: [Tags.dashboard, Tags.medical, Tags.infoModal],
    title: 'T_ACCOUNT_SUMMARY_EXPLANATION',
  },
  payNow: {
    name: 'PayNow',
    path: '/modal/pay-now',
    tags: [Tags.dashboard, Tags.infoModal, Tags.medical],
    title: 'T_EDIT_CLAIM_NOTE',
  },
  pcpChangeInProgress: {
    name: 'PcpChangeInProgress',
    path: '/modal/pcp-change-in-progress',
    tags: [Tags.pcp],
    title: 'T_PCP_CHANGE_IN_PROGRESS',
  },
  callToChangePcp: {
    name: 'CallToChangePcp',
    path: '/modal/call-to-change-pcp',
    tags: [Tags.pcp],
    title: 'T_CHANGE_PCP',
  },
  idCards: {
    trackingKeys: ['constructedId'],
    name: 'IdCards',
    path: '/modal/id-cards',
    title: 'T_ID_CARDS',
  },
  pcpChangeUnavailable: {
    name: 'PCPChangeLockedIn',
    path: '/modal/pcp-change-unavailable',
    title: 'T_PCP_CHANGE_UNAVAILABLE',
  },
  waysToSave: {
    name: 'WaysToSave',
    path: '/ways-to-save',
    tags: [Tags.waysToSave],
    title: 'T_WAYS_TO_SAVE',
  },
  waysToSaveModal: {
    name: 'WaysToSaveModal',
    path: '/modal/learn-more',
    tags: [Tags.waysToSave],
    title: 'T_WAYS_TO_SAVE',
  },
  findCare: {
    name: 'FindCare&CostsIntermediary',
    path: '/find-care',
    tags: [Tags.providers],
    title: 'T_FIND_CARE',
  },
  ovc: {
    name: 'OptumVirtualCare',
    path: '/ovc',
    title: 'T_OPTUM_VIRTUAL_CARE',
  },
  premiumPayments: {
    name: 'PremiumPayments',
    path: '/premium-payments',
    title: 'T_PREMIUM_PAYMENTS',
  },
  pharmacy: {
    name: 'Pharmacies&Prescriptions',
    path: '/pharmacy',
    tags: [Tags.rx],
    title: 'T_PHARMACY',
  },
  recommendations: {
    trackingKeys: ['recommendations'],
    name: 'Recommendations',
    path: '/recommendations',
    title: 'T_RECOMMENDATIONS',
  },
  help: {
    name: 'Help',
    path: '/help',
    tags: [Tags.help],
    title: 'T_HELP',
  },
  pcpReferrals: {
    name: 'PcpReferrals',
    path: '/pcp-referrals',
    tags: [Tags.pcp],
    title: 'T_PCP_REFERRALS',
  },
  orderCovidTests: {
    name: 'OrderCovidTests',
    path: '/order-covid-tests',
    title: '',
  },
  idCardspaperlessPreferences: {
    name: 'IdCardsPaperlessPreferences',
    path: '/idcards-paperless-preferences',
    tags: [Tags.idCardspaperlessPreferences],
    title: 'T_ID_CARDS_PAPERLESS_PREFERENCES',
  },
  idCardAccessibility: {
    name: 'IdCardAccessibilityModal',
    path: '/modal/id-cards/id-card-accessibility',
    tags: [Tags.idCardAccessibilityModal],
    title: 'T_ID_CARDS',
  },
  mrNudgeModal: {
    name: 'MrNudgeModal',
    path: '/modal/mr-nudge',
    title: 'T_MR_NUDGE',
  },
};

export const PagesData = Object.keys(PagesDataWithoutUUID).reduce(
  (aggr, pageName) => ({
    ...aggr,
    [pageName]: {
      ...PagesDataWithoutUUID[pageName],
      uuid: uuidv4(),
    },
  }),
  {} as Record<PageKeys, IPageData>,
);

export const PagesDataByPath: Record<string, IPageData> = Object.keys(PagesData).reduce(
  (aggr, pageName) => ({
    ...aggr,
    [PagesData[pageName].path]: PagesData[pageName],
  }),
  {},
);

export const PagesDataByPathAsList: Array<string> = Object.values(PagesData).map(({ path }) => path);
