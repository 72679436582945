/*
This will split the bundle by route so people can download multiple chucks in parralel greatly decreasing the overall bundle time
Sadly it will most likely break Requestly (long time coming sadly).
You can still try to use it. If it's something that's route specific swap out the route bundle.
If it's a general component shared between components swap out the main bundle.

BE AWARE some paths such as /activate are watched by our load balancer here
https://github.com/AudaxHealthInc/neptune-deploy/blob/30526504715aa7634768e92485aef9659071af4e/group_vars/activate-ui.yml#L18-L21
*/

/* eslint-disable max-len */
import React from 'react';

/* Route Splitting Pages - In order of appearance */

export const CallToChangePcpModal = React.lazy(() =>
  import(/* webpackChunkName: "CallToChangePcpModal" */ 'scripts/features/modals/pcp/call-to-change-pcp').then(
    module => ({
      default: module.CallToChangePcpModal,
    }),
  ),
);
export const IdCard = React.lazy(() =>
  import(/* webpackChunkName: "IdCard" */ 'scripts/features/modals/id-cards/id-card').then(module => ({
    default: module.IdCard,
  })),
);
export const InactiveModal = React.lazy(() =>
  import(/* webpackChunkName: "InactiveModal" */ 'scripts/features/modals/inactive/inactive').then(module => ({
    default: module.InactiveModal,
  })),
);
export const ActivateVideoModal = React.lazy(
  () =>
    import(/* webpackChunkName: "ActivateVideoModal" */ 'scripts/features/modals/activate-video/activate-video-modal'),
);
export const ActivateTranscriptModal = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ActivateTranscriptModal" */ 'scripts/features/modals/activate-video/activate-transcript-modal'
    ),
);
export const PayNowModal = React.lazy(() =>
  import(/* webpackChunkName: "PayNowModal" */ 'scripts/features/modals/pay-now/pay-now-modal').then(module => ({
    default: module.PayNowModal,
  })),
);

export const IDCardAccessibilityModal = React.lazy(() =>
  import(/* webpackChunkName: "PayNowModal" */ 'scripts/features/modals/id-cards/id-card-accessibility').then(
    module => ({
      default: module.IDCardAccessibilityModal,
    }),
  ),
);
export const PcpChange = React.lazy(() =>
  import(/* webpackChunkName: "PcpChange" */ 'scripts/features/modals/pcp/pcp-change-container.component').then(
    module => ({
      default: module.PcpChange,
    }),
  ),
);
export const PcpChangeUnavailableModal = React.lazy(() =>
  import(
    /* webpackChunkName: "PcpChangeUnavailableModal" */ 'scripts/features/modals/pcp-change-unavailable/pcp-change-unavailable'
  ).then(module => ({
    default: module.PcpChangeUnavailableModal,
  })),
);
export const WaysToSaveModal = React.lazy(() =>
  import(/* webpackChunkName: "WaysToSaveModal" */ 'scripts/features/modals/ways-to-save/ways-to-save').then(
    module => ({
      default: module.WaysToSaveModal,
    }),
  ),
);
export const Coronavirus = React.lazy(() =>
  import(/* webpackChunkName: "Coronavirus" */ 'scripts/features/coronavirus/coronavirus').then(module => ({
    default: module.Coronavirus,
  })),
);
export const HealthResources = React.lazy(() =>
  import(/* webpackChunkName: "HealthResources" */ 'scripts/features/health-resources/health-resources').then(
    module => ({
      default: module.HealthResources,
    }),
  ),
);
export const OptumVirtualCareContainer = React.lazy(() =>
  import(
    /* webpackChunkName: "OptumVirtualCareContainer" */ 'scripts/features/optum-virtual-care/optum-virtual-care-container'
  ).then(module => ({
    default: module.OptumVirtualCareContainer,
  })),
);
export const Pharmacy = React.lazy(() =>
  import(/* webpackChunkName: "Pharmacy" */ 'scripts/features/pharmacy/pharmacy').then(module => ({
    default: module.Pharmacy,
  })),
);
export const Recommendations = React.lazy(() =>
  import(/* webpackChunkName: "Recommendations" */ 'scripts/features/recommendations/recommendations').then(module => ({
    default: module.Recommendations,
  })),
);
export const Help = React.lazy(() =>
  import(/* webpackChunkName: "Help" */ 'scripts/features/help/help').then(module => ({
    default: module.Help,
  })),
);
export const ProviderReferrals = React.lazy(() =>
  import(/* webpackChunkName: "ProviderReferrals" */ 'scripts/features/pcp-referrals/provider-referrals').then(
    module => ({
      default: module.ProviderReferrals,
    }),
  ),
);

export const IdCardsPaperlessPreferences = React.lazy(() =>
  import(
    /* webpackChunkName: "IdCardsPaperlessPreferences" */ 'scripts/features/idcards-paperless-preferences/idcards-paperless-preferences'
  ).then(module => ({
    default: module.IdCardsPaperlessPreferences,
  })),
);

export const ConnectedWaysToSave = React.lazy(() =>
  import(/* webpackChunkName: "ConnectedWaysToSave" */ 'scripts/features/ways-to-save/ways-to-save-connected').then(
    module => ({
      default: module.ConnectedWaysToSave,
    }),
  ),
);
export const ChatterBoxContainer = React.lazy(() =>
  import(/* webpackChunkName: "ChatterBoxContainer" */ 'scripts/features/advantage/chatter-box-container').then(
    module => ({
      default: module.ChatterBoxContainer,
    }),
  ),
);
export const PremiumPayments = React.lazy(() =>
  import(/* webpackChunkName: "PremiumPayments" */ 'scripts/features/premium-payments/premium-payments').then(
    module => ({
      default: module.PremiumPayments,
    }),
  ),
);
export const RxRedirect = React.lazy(() =>
  import(/* webpackChunkName: "RxRedirect" */ 'scripts/features/rx-redirect/rx-redirect').then(module => ({
    default: module.RxRedirect,
  })),
);
export const GenoaSsoRedirect = React.lazy(() =>
  import(/* webpackChunkName: "GenoaSsoRedirect" */ 'scripts/features/genoa/genoa-sso-redirect').then(module => ({
    default: module.GenoaSsoRedirect,
  })),
);
export const OrderCovidTests = React.lazy(() =>
  import(/* webpackChunkName: "OrderCovidTests" */ 'scripts/config/routes/order-covid-tests').then(module => ({
    default: module.OrderCovidTests,
  })),
);

/* Imported Application Routes  */
export const LoginRoute = React.lazy(() =>
  import(/* webpackChunkName: "LoginRoute" */ 'scripts/config/routes/login-route').then(module => ({
    default: module.LoginRoute,
  })),
);
export const InternalErrorRoute = React.lazy(() =>
  import(/* webpackChunkName: "InternalErrorRoute" */ 'scripts/config/routes/internal-error-route').then(module => ({
    default: module.InternalErrorRoute,
  })),
);
export const SeeYouLaterRoute = React.lazy(() =>
  import(/* webpackChunkName: "SeeYouLaterRoute" */ 'scripts/config/routes/see-you-later-route').then(module => ({
    default: module.SeeYouLaterRoute,
  })),
);
export const InternalRedirect = React.lazy(() =>
  import(/* webpackChunkName: "InternalRedirect" */ 'scripts/features/internal-redirect/internal-redirect').then(
    module => ({
      default: module.InternalRedirect,
    }),
  ),
);
export const LogoutRoute = React.lazy(() =>
  import(/* webpackChunkName: "LogoutRoute" */ 'scripts/config/routes/logout-route').then(module => ({
    default: module.LogoutRoute,
  })),
);
export const UnauthorizedErrorRoute = React.lazy(() =>
  import(/* webpackChunkName: "UnauthorizedErrorRoute" */ 'scripts/config/routes/unauthorized-error-route').then(
    module => ({
      default: module.UnauthorizedErrorRoute,
    }),
  ),
);
export const DashboardRoute = React.lazy(() =>
  import(/* webpackChunkName: "DashboardRoute" */ 'scripts/config/routes/dashboard-route').then(module => ({
    default: module.DashboardRoute,
  })),
);

export const MrNudgeModal = React.lazy(() =>
  import(/* webpackChunkName: "MrNudgeModal" */ 'scripts/features/modals/mr-nudge/mr-nudge-modal').then(module => ({
    default: module.MrNudgeModal,
  })),
);

export default {
  CallToChangePcpModal,
  IdCard,
  InactiveModal,
  ActivateVideoModal,
  ActivateTranscriptModal,
  PayNowModal,
  PcpChange,
  PcpChangeUnavailableModal,
  WaysToSaveModal,
  LoginRoute,
  InternalErrorRoute,
  SeeYouLaterRoute,
  Coronavirus,
  HealthResources,
  OptumVirtualCareContainer,
  Pharmacy,
  Recommendations,
  Help,
  ProviderReferrals,
  IdCardsPaperlessPreferences,
  ConnectedWaysToSave,
  ChatterBoxContainer,
  PremiumPayments,
  RxRedirect,
  GenoaSsoRedirect,
  OrderCovidTests,
  InternalRedirect,
  LogoutRoute,
  UnauthorizedErrorRoute,
  DashboardRoute,
  IDCardAccessibilityModal,
  MrNudgeModal,
};
